import checkUrls from 'lib/validators/checkUrls'
import required from 'lib/validators/required'
import zeroOrPositive from 'lib/validators/zeroOrPositive'

export const validationRules = {
  bidCap: [
    zeroOrPositive,
  ],
  impressions: [
    zeroOrPositive,
  ],
  bidCpa: [
    zeroOrPositive,
  ],
  budget: [
    zeroOrPositive,
  ],
  productUrl: [
    required,
    checkUrls,
  ],
  videoDescriptionText: [
    required,
  ],
}
