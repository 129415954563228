import checkUrls from 'lib/validators/checkUrls'
import zeroOrPositive from 'lib/validators/zeroOrPositive'
import cyrillic from 'lib/validators/cyrillic'

export const validationRules = {
  // title: [{ required: true }],
  // externalId: [{ required: true }],
  // platform: [{ required: true }],
  // start: [{ required: true }],
  // end: [{ required: true }],
  bidCap: [
    zeroOrPositive,
  ],
  impressions: [
    zeroOrPositive,
  ],
  bidCpa: [
    zeroOrPositive,
  ],
  budget: [
    zeroOrPositive,
  ],
  chatbotProductLink: [
    checkUrls,
    // cyrillic,
  ],
}
