import positiveNum from 'lib/validators/positiveNum'
import required from 'lib/validators/required'

export default function validationRules () {
  return {
    bidCap: [
      positiveNum,
    ],
    impressions: [
      positiveNum,
    ],
    frequencyCount: [
      positiveNum,
    ],
    agencies: [
      required,
    ],
  }
}
